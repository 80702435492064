<template>
  <div class="all">
    <!-- 任务Detalhes界面 -->
    <div class="title">{{ $t("taskdetails[0]") }}</div>
    <div class="tab">
      <table>
        <tr>
          <!-- 表头的循环 -->
          <th v-for="(n, ind) in onedata" :key="ind">{{ n }}</th>
        </tr>
        <tr ref="trcolor" v-for="(i, index) in userdata" :key="index">
          <td
            v-for="(name, num) in titledata"
            :key="num"
            @click="turn(num, index)"
          >
            {{ i[name] }}
          </td>
          <!-- i.标题名，标题名用循环得到 -->
        </tr>
      </table>
    </div>
    <van-dialog v-model="see" title="" :show-confirm-button="false">
      <div>
        <img src="" alt="" />
      </div>
      <img
        @click="down()"
        src="../../../public/static/icon2/delete.png"
        alt=""
      />
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //   user: "用户名",
      //   time: "领取时间",
      //   img: "任务截图",
      see: false,
      onedata: ["用户名", "领取时间", "任务截图"],
      titledata: ["user", "time", "img"],
      userdata: [
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
        { user: "189660******", time: "2020-02-02", img: "Detalhes" },
      ],
    };
  },
  mounted() {},
  methods: {
    down() {
      this.see = false;
    },
    turn(num, index) {
      // console.log(index);
      console.log(index);
      if (num == 2) {
        this.see = true;
      }
    },
  },
};
</script>
<style scoped>
.all {
  height: 100vh;
  background-color: #eff0f2;
  overflow: auto;
}
.title {
  background: #4e51bf;
  color: #fff;
  font-size: 17.5px;

  width: 100%;
  height: 45px;
  text-align: center;
  line-height: 45px;
  position: fixed;
}
.tab {
  margin: 20px;
  height: 100vh;
  margin-top: 60px;
}
table {
  width: 100%;
  text-align: center;
  box-shadow: 1px 1px 5px 3px #dcdddf;
  border-collapse: collapse;
}
table th {
  font-size: 13px;
}
table th:nth-of-type(2) {
  border-left: 2px #fff solid;
  border-right: 2px #fff solid;
}
table tr {
  background: #eff0f2;
  height: 35px;
}
table td:nth-of-type(2) {
  border-left: 2px #fff solid;
  border-right: 2px #fff solid;
}
table td {
  width: 30%;
  font-size: 12px;
  color: gray;
}
table td:nth-of-type(3) {
  color: #4e51bf;
}
table tr:nth-child(odd) {
  background-color: #e1e1f9;
}
table tr:nth-of-type(1) {
  background: #b3b5fc;
}
img {
  width: 35px;
  position: fixed;
  top: 360px;
  left: 128px;
}
.van-dialog {
  height: 350px;
  width: 280px;
  overflow: initial;
}
</style>
